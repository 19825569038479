import {
	SAVE_FORM,
} from '../actions';

const INIT_STATE = {
	form: {
        title: "",
        picture: "",
        objective: "",
        content_type: [],
        category: "",
        description: "",
        languages: [],
        country: "",
        gender: "",
        age_group: [],
        other: "",
        budget: "",
        submission_deadline: "",
        product_to_send: "",
        video_style_ref: "",
        product_ship_days: "",
        influencer_keep_product: "",
        logo_appearance: "",
        support_doc: "",
        oppose_pref: [],
        tnc: false
    },
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SAVE_FORM:
			return { ...state, form: {...state.form, [action.payload.name]: action.payload.value}};

		default: return { ...state };
	}
}