import {
  LOGOUT_BRAND, SET_COOKIE, SET_USER_DATA
} from '../actions';

export const setCookie = ({session, exp}) => (
  {
    type: SET_COOKIE,
    payload : {session, exp}
  });

export const setUserData = ({company_name, profile_picture, email, id}) => (
  {
    type: SET_USER_DATA,
    payload : {company_name, profile_picture, email, id}
  });

export const logoutBrand = (history) => ({
  type: LOGOUT_BRAND,
  payload : {history}
});