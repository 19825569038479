import appLocaleData from "react-intl/locale-data/id";
import idMessages from "../locales/id_ID";

const idLang = {
  messages: {
    ...idMessages
  },
  locale: "id-ID",
  data: appLocaleData
};
export default idLang;
