/* Gogo Language Texts

Table of Contents

01. General
02. User Login, Logout, Register
03. Menu
04. Pages:
- Errors
- Services
05. Buttons
06. Tables
07. Campaigns
08. Influencers
09. Case Study
10. Messages
11. Modal
12. Deliverables
13. Review

*/
module.exports = {
  /* 01.General */
  "general.copyright": "SushiVid © 2019 All Rights Reserved.",
  "general.location": "Location",
  "general.socialMedia": "Social Media",
  "general.general": "General",
  "general.fees": "Fees",
  "general.free": "Free",
  "general.above": "and above",
  "general.budget": "Budget",
  "general.accepted": "Accepted",
  "general.rejected": "Rejected",
  "general.goLive": "Posted on",
  "general.price": "Price",
  "general.pending": "Pending",
  "general.shortlist": "Shortlist",
  "general.stage": "Stage",
  "general.date": "Date",
  "general.linked": "Linked",
  "general.invoices": "Invoices",
  "general.proposals": "{count, plural, =0 {no proposals} one {# proposal} other {# proposals}}",
  "general.messages": "{count, plural, =0 {No messages} one {# message} other {# messages}}",
  "general.campaigns": "{count, plural, =0 {no campaigns} one {# campaign} other {# campaigns}}",
  "general.additionalInfo": "Additional Information *",
  "general.action-irrevocable": "This action is irrevocable!",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Influencer Login",
  "user.login-subtitle": "Continue with any of the following social media:",
  "user.login.intros-title": "I'M AN INFLUENCER",
  "user.login.intros-subtitle": "I’m looking for sponsorships online",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forget password?",
  "user.do-not-have-account-question": "Don't have an account yet?",
  "user.register-here": "Register here",
  "user.register.context": "Please use this form to register. {br} If you are a member, please {link}",
  "user.fullname": "Full Name",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",
  "brand.login-title": "Brand Login",
  "brand.login-subtitle": "Please key in your credentials here",
  "brand.register.have-account-question": "Have an account?",
  "brand.register.login-here": "Login here",
  "brand.companyName": "Company Name",
  "brand.login.intros-title": "I'M A BRAND",
  "brand.login.intros-subtitle": "I’m looking for influencers online",
  "brand.intros-1": "Get proactive in reaching out to our brands for sponsorships",
  "brand.intros-2": "Guaranteed payment and account management for your influencer career",
  "brand.intros-3": "No contracts",
  "brand.intros-4": "Have your say",

  /* 03.Menu */
  /* Sidebar */
  "menu.sidebar.home": "Home",
  "menu.sidebar.wallet": "Wallet",
  "menu.sidebar.services": "Services",
  "menu.sidebar.campaigns": "Campaigns",
  "menu.sidebar.campaigns.sushivid": "SushiVid",
  "menu.sidebar.tooltip.sushivid": "Cost Per Post",
  "menu.sidebar.campaigns.gosharelah": "GoShareLah",
  "menu.sidebar.tooltip.gosharelah": "Cost Per Share",
  "menu.sidebar.campaigns.create": "Create New Campaign",
  "menu.sidebar.campaigns.myCampaigns": "My Campaigns",
  "menu.sidebar.campaigns.myGslCampaigns": "My Share Campaigns",
  "menu.sidebar.campaigns.ongoing": "Ongoing Campaigns",
  "menu.sidebar.campaigns.kols-invited": "KOLs Invited",
  "menu.sidebar.campaigns.campaignsInvitations": "Campaign Invitations",
  "menu.sidebar.campaigns.pendingProjects": "Pending Campaigns",
  "menu.sidebar.sponsorships": "Sponsorship",
  "menu.sidebar.submissions": "Submissions",
  "menu.sidebar.drafts": "Drafts",
  "menu.sidebar.modal.chat": "Chat",
  "menu.sidebar.modal.view": "View",
  // "menu.app": "Home",
  "menu.dashboards": "Dashboards",
  "menu.gogo": "Gogo",
  "menu.start": "Welcome",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.survey": "Survey",
  "menu.todo": "Todo",
  "menu.search": "Search",
  "menu.docs": "Docs",
  "menu.blank-page": "Blank Page",

  /* 04.Pages */

  /* Templates */
  "pages.templates.header": "Browse templates of other brands",
  "pages.templates.title": "Templates",
  "pages.templates.cps": "Cost Per Share",
  "pages.templates.cpp": "Cost Per Post",
  "pages.templates.cpsale": "Cost Per Sale",

  /* Error Page */
  "pages.error-title": "Oops... This page doesn't exist!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",

  /* Services */
  "pages.services.header": "Our services",

  /* Services - SushiVid - Normal */
  "pages.services.sushiVidNormal.title": "Propose",
  "pages.services.sushiVidNormal.intros-1": "Propose in 1 minute",
  "pages.services.sushiVidNormal.intros-2": "Check out new brands",
  "pages.services.sushiVidNormal.intros-3":
    "Browse from over 20 brands anytime",

  /* Services - SushiVid - VIP */
  "pages.services.sushiVidVIP.title": "Accept",
  "pages.services.sushiVidVIP.intros-1": "By invitation only!",
  "pages.services.sushiVidVIP.intros-2": "High chance of obtaining job",
  "pages.services.sushiVidVIP.intros-3": "Little to no proposal required",

  /* Services - GoShareLah */
  "pages.services.goShareLah.title": "Share",
  "pages.services.goShareLah.intros-1": "Share good deals, spread good news",
  "pages.services.goShareLah.intros-2": "No proposal required - Confirmed job",
  "pages.services.goShareLah.intros-3": "Go share lah!",

  /* Services - ConfirmPlusChop */
  "pages.services.confirmPlusChop.title": "Sell",
  "pages.services.confirmPlusChop.intros-1": "Request for product",
  "pages.services.confirmPlusChop.intros-2": "Create content your way",
  "pages.services.confirmPlusChop.intros-3": "Earn commission from each sale",

  /* Sponsorship */
  "pages.sponsorhip.header": "Sponsorships",

  /* Proposal */
  "pages.proposals.collabBox.influencers": "Welcome to your collaboration box! You have {count, plural, =0 {no KOLs} one {# KOL} other {# KOLs}} in production.",
  "pages.proposals.collabBox.pending-action": "{pendingCount} out of {count, plural, one {# proposal} other {# proposals}} pending your action",
  "pages.campaigns.collabBox.campaigns": "Welcome to your Collaboration Box. You have {count, plural, =0 {no campaigns} one {# campaign} other {# campaigns}} in progress",
  "pages.proposals.brand-waiting": "Pending confirmation from KOL",
  "pages.proposals.kols-selected": "{count, plural, one {# KOL} other {# KOLs}} Selected",
  "pages.proposals.influencer-waiting": "KOL is waiting for your response",
  "pages.proposals.waiting-for-submission": "Pending submission from KOL",
  "pages.proposals.review-submission": "Please review the draft/submission",
  "pages.proposals.video-rejected": "Pending Submission from KOL",
  "pages.proposals.video-accepted": "Pending Review",
  "pages.proposals.completed": "Completed",

  /* Dashboard */
  "pages.dashboard.header": "Dashboard",
  "pages.dashboard.newCampaign": "Create a new campaign",
  "pages.dashboard.draftDeadline": "{count, plural, one {# day} other {# days}} to draft deadline",
  "pages.dashboard.draftCount": "{count, plural, one {# draft} other {# drafts}} pending review",
  "pages.dashboard.paymentReleaseCount": "{count, plural, one {# KOL} other {# KOLs}} pending payment release",
  "pages.dashboard.deliverablesApprovalCount": "{count, plural, one {# KOL} other {# KOLs}} pending deliverable approval",
  "pages.dashboard.prepDeadlineCount": "{count, plural, one {# KOL} other {# KOLs}} pending deadline setting",
  "pages.dashboard.calendar": "Calendar of the events and deadlines",
  "pages.dashboard.logs": "Tasks Panel",
  "pages.dashboard.pendingInv1": "You have ",
  "pages.dashboard.pendingInv2": "{cta} pending invitation",
  "pages.dashboard.startDate": "Start Date",
  "pages.dashboard.endDate": "End Date",
  "pages.dashboard.choosenDate": "The choosen day should be more than today's date",
  "page.dashboard.changeEventDate": "Change the event date",
  "page.dashboard.table.header": "Ongoing {count, plural, one {Campaign} other {Campaigns}}",

  /* GSL */
  "pages.gsl.new-campaign": "New Share Campaign",
  "pages.gsl.title": "Title",
  "pages.gsl.caption": "Caption",
  "pages.gsl.hashtags": "Hashtag",
  "pages.gsl.removeHashtags": "Pay to remove #GoShareLah #ad in caption? {br} {cta} MYR 25 (Promotion Price)",
  "pages.gsl.displayLink": "Display link in caption?",
  "pages.gsl.dropzone": "Image Upload (Optional)",
  "pages.gsl.linkImage": "Add Link (Link Image)",
  "pages.gsl.image": "Image Upload (File Image)",
  "pages.gsl.imageOptions": "Which image would you like to display?",
  "pages.gsl.budget": "Set your budget (MYR) {br} {cta}",
  "pages.gsl.budgetDescription": "Upon payment, you may set your cost-per-share for each social media, otherwise, it will default to our set cost-per-share. (Minimum budget: MYR 100)",
  "pages.gsl.country": "Target Country",
  "pages.gsl.tiering": "Activate Tiering Cost Per Share?",
  "pages.gsl.amount": "Amount",
  "pages.gsl.edit.footnote-1": "* Content shared will be removed by KOL with followers:",
  "pages.gsl.edit.footnote-2": "- 100,000 and below in 5 days",
  "pages.gsl.edit.footnote-3": "- 100,000 and above in 2 days",

  /* Brands */
  "pages.brands.navItem-1": "Profile",
  "pages.brands.navItem-2": "Account",
  "pages.brands.navItem-3": "Transaction",
  "pages.brands.navItem-1.subtitle-1": "Security",
  "pages.brands.profile.username": "Username",
  "pages.brands.profile.email": "Email",
  "pages.brands.profile.website": "Website",
  "pages.brands.profile.countryCode": "Country Code",
  "pages.brands.profile.phone": "Phone",
  "pages.brands.profile.description": "Description",
  "pages.brands.password.old_password": "Current Password",
  "pages.brands.password.password": "New Password",
  "pages.brands.password.confirm_password": "Confirm New Password",
  "pages.brands.billing-details": "Billing Details",
  "pages.brands.billing.companyName": "Company Name",
  "pages.brands.billing.addressLineOne": "Address Line 1",
  "pages.brands.billing.addressLineTwo": "Address Line 2",
  "pages.brands.billing.city": "City",
  "pages.brands.billing.state": "State",
  "pages.brands.billing.postCode": "Post Code",
  "pages.brands.billing.country": "Country",
  "pages.brands.billing.attentionTo": "Attention to",
  "pages.brands.payment.title": "Payment Details",
  "pages.brands.payment.amount": "Amount",
  "pages.brands.payment.currency": "Currency",
  "pages.brands.walletBalance": "Wallet Balance",
  "pages.influencers.currentFunds": "Current Funds",
  "pages.brands.deposit": "Deposit to your account",
  "pages.brands.notification": "Please make sure your billing details are up to date",
  "pages.brands.notification.title": "Notifications",
  "pages.influencer.additionalInfo.dateBirth": "Date of Birth",
  "pages.influencer.additionalInfo.country": "Country",
  "pages.influencer.additionalInfo.gender": "Gender",
  "pages.influencer.additionalInfo.race": "Race",
  "pages.influencer.additionalInfo.occupation": "Occupation",
  "pages.influencer.additionalInfo.languages": "Languages",
  "pages.influencer.additionalInfo.hobby": "Hobby",
  "pages.influencer.additionalInfo.category": "Category",
  "pages.influencer.additionalInfo.descriptionUser": "Choose 3 best words to describe you*",
  "pages.influencer.additionalInfo.descriptionContent": "Choose 3 best words to describe your content*",
  "influencer.show.description": "Description",
  "influencer.show.location": "Location",
  "influencer.show.category": "Category",
  // "app.search.placeholder": "Searh"

  /* Drafts */
  "pages.draft.navbar-1": "Submit File",
  "pages.draft.navbar-2": "Drafts",
  "pages.draft.noDrafts": "No drafts submitted yet",
  "pages.draft.upload-files": "Upload files here",
  "pages.draft.uploaded-files": "Files",

  /* Invoice */
  "pages.invoice.goBackLink": "Back to campaign",

  /* Receipt */
  "pages.receipt.goBackLink": "Back to wallet",

  /* 05.Buttons */
  "button.view-campaign": "View Campaign",
  // "button.dashboard.draftCount": "Review Drafts",
  // "button.dashboard.paymentReleaseCount": "Release Payment",
  // "button.dashboard.deliverablesApprovalCount": "Approve Deliverables",
  // "button.dashboard.prepDeadlineCount": "Set Deadlines",
  "button.update": "Update",
  "button.discard-all": "Discard All & Start New Search",
  "button.upload": "Upload",
  "button.pay": "Pay",
  "button.payNow": "Pay now",
  "button.paying": "Paying",
  "button.viewMore": "View More",
  "button.deposit": "Deposit",
  "button.chat": "Chat",
  "button.more": "More",
  "button.invite": "Invite",
  "button.viewBrief": "View brief",
  "button.viewSubmissions": "View submissions",
  "button.goBack": "Go back",
  "button.seeReview": "See Review",
  "button.addReview": "Add a Review",
  "button.payCreate": "Pay and Create",
  "button.payout": "Payout Request",
  "button.continuePayout": "Continue to payout request",
  "button.propose": "Propose",
  "button.submit": "Submit",
  "button.payment": "Withdraw",
  "button.link_submission": "Submit Live Link",
  "button.insights_submission": "Submit Insights",
  "button.release-payment": "Release Payment",
  "button.draft_submission": "Submit Draft",
  "button.fileUpload": "Files upload / download",
  "button.moreCampaign": "More about campaign",
  "button.goToCollabBox": "Go to Collaboration Box",
  "button.setDate": "Set a LIVE date",
  "button.set-deliverables": "Approve Deliverables",
  "button.set-deadlines": "Set Deadlines",
  "button.review-drafts": "Review Drafts",
  "button.view-submissions": "View Submissions",
  "button.okay": "Okay",
  "button.no": "No",
  "button.goSubmit": "Go to Resubmit",
  "button.save": "Save",
  "button.cancel": "Cancel",
  "button.link": "Link",
  "button.view": "View",
  "button.view-proposals": "View Proposals",
  "button.archive": "Archive / Reject",
  "button.shortlist-all": "Shortlist All",
  "button.hire-all": "Hire All",
  "button.archive-all": "Archive / Reject All",
  "button.unsorted": "Select",
  "button.sorted": "Hire",
  "button.close": "Close",
  "button.createCamp": "Create a similar campaign",
  "button.shortlist": "Shortlist",
  "button.confirm": "Confirm",
  "button.confirming": "Confirming",
  "button.uploading": "Uploading",
  "button.submitting": "Submitting",
  "button.select-kol": "Select KOL",
  "button.selected-kol": "Selected {icon}",
  "button.invite-more": "Invite more",
  "button.view-invitations": "View invitations",
  "button.invite-more-creators": "Invite KOLs",
  "button.cancel-invitation": "Cancel Invitation",
  "button.proceed-to-payment": "Release payment to KOL",
  "button.download-pdf": "Download PDF",

  /* 06.Tables */
  "table.transaction.title": "Transaction Details:",
  "table.transaction.column.date": "Date & Time",
  "table.transaction.column.payment": "Paid By & Reference No.",
  "table.transaction.column.transaction": "Description",
  "table.transaction.column.amount": "Amount",
  "table.transaction.column.currency": "Currency",
  "table.transaction.column.link": "Invoice Link",
  "table.dashboard.column.image": "Type",
  "table.dashboard.column.title": "Title",
  "table.dashboard.column.proposals": "Proposals",
  "table.dashboard.column.status": "Status",
  "table.dashboard.pending-proposals": "{count, plural, =0 {# new proposals} one {# new proposal} other {# new proposals}}",
  "table.dashboard.ongoing-proposals": "{count, plural, =0 {# active proposals} one {# active proposal} other {# active proposals}}",

  /* 07.Campaigns */

  /* Campaigns - New Proposal */
  "campaigns.new.step-name-1": "Step 1",
  "campaigns.new.step-desc-1": "Basic",
  "campaigns.new.step-name-2": "Step 2",
  "campaigns.new.step-desc-2": "Basic",
  "campaigns.new.step-name-3": "Step 3",
  "campaigns.new.step-desc-3": "Basic",
  "campaigns.new.header": "What is your budget?",
  "campaigns.new.addOn": "Do you want to create a GoShareLah campaign as well?",
  "campaigns.new.title": "Title",
  "campaigns.new.reviewCampaign": "Review your campaign",
  "campaigns.new.pictures": "Image(s)",
  "campaigns.new.uploaded-pictures": "Your uploaded pictures: ",
  "campaigns.new.objective": "Objective",
  "campaigns.new.contentType": "What type of content would you like?",
  "campaigns.new.logoAppearance": "Brand Logo (Optional)",
  "campaigns.new.supportingDoc": "Support Document (Optional)",
  "campaigns.new.opposePref": "Are you opposed to any of the below?",
  "campaigns.new.created-campaign": "Campaign successfully created",
  "campaigns.new.created-campaign-error": "Campaign wasn't created!",
  "campaigns.new.content": "For clarification purposes, we vet through every entry before they are published. {br} You should receive an email from us within 24 hours.",
  "campaigns.new.content-error": "Please try again or contact us!",
  "campaigns.new.link": "You can see your pendings campaigns {cta}",
  "campaigns.new.gender": "Which gender are you targeting?",
  "campaigns.new.country": "Preferred target market",
  "campaigns.new.languages": "Languages",
  "campaigns.new.category": "Which  category would best describe your company product?",
  "campaigns.new.description": "Description",
  "campaigns.new.ageGroup": "How old are your consumers?",
  "campaigns.new.budget": "How much are you willing to spend per KOL?",
  "campaigns.new.productShipDays": "Estimated product shipping time after you've accepted KOL",
  "campaigns.new.influencerKeepProduct": "Are you giving / lending the product to the KOL?",
  "campaigns.new.productToSend": "Do you need to send physical products to the KOL?",
  "campaigns.new.videoStyleRef": "Do you have a reference video, image or post in mind? If yes, insert link below (Optional)",
  "campaigns.new.other": "Additional Information (Optional)",
  "campaigns.new.budgetRange-1": "Total target",
  "campaigns.new.budgetRange-2": "Involved KOLs",
  "campaigns.new.submissionDeadline": "Do you have an estimated / expected launch date?",
  "campaigns.new.deliverables": "Deliverables",
  "campaigns.proposals.review": "To Review {br} ( {num, plural, =0 {# proposals} one {# proposal} other {# proposals}} )",
  "campaigns.proposals.review.small": "Review {br} ( {num} )",
  "campaigns.proposals.review.title": "Review",
  "campaigns.proposals.shortlisted": "Shortlisted {br} ( {num, plural, =0 {# proposals} one {# proposal} other {# proposals}} )",
  "campaigns.proposals.shortlisted.small": "Shortlisted {br} ( {num} )",
  "campaigns.proposals.shortlisted.title": "Shortlisted",
  "campaigns.proposals.in_production": "In Production {br} ( {num, plural, =0 {# proposals} one {# proposal} other {# proposals}} )",
  "campaigns.proposals.in_production.small": "Production {br} ( {num} )",
  "campaigns.proposals.in_production.title": "In production",
  "campaigns.proposals.in_production.empty": "No proposals in production",
  "campaigns.proposals.pending.title": "Pending",
  "campaigns.proposals.completed.title": "Completed",
  "campaigns.proposals.ongoing.title": "Ongoing",
  "campaigns.proposals.archived": "Archived {br} ( {num, plural, =0 {# proposals} one {# proposal} other {# proposals}} )",
  "campaigns.proposals.archived.small": "Archived {br} ( {num} )",
  "campaigns.proposals.archived.title": "Archived",
  "campaigns.proposals.review.empty": "No proposals to review",
  "campaigns.proposals.shortlisted.empty": "No shortlisted proposals",
  "campaigns.proposals.archived.empty": "No archived proposals",
  "campaigns.proposals.total-earnings": "Earned MYR {num} in past campaigns with SushiVid",
  "campaigns.ongoingCampaigns": "Ongoing Campaigns {br} ( {num, plural, one {# campaign} other {# campaigns}} )",
  "campaigns.ongoingCampaigns.small": "Ongoing Campaigns {br} ( {num} )",
  "campaigns.pendingCampaigns": "Pending Campaigns {br} ( {num, plural, one {# campaign} other {# campaigns}} )",
  "campaigns.pendingCampaigns.small": "Pending Campaigns {br} ( {num} )",
  "campaigns.rejected": "Rejected Campaigns",
  "campaigns.completed.title": "Completed Campaigns",
  "campaigns.completedCampaigns": "Completed Campaigns {br} ( {num, plural, one {# campaign} other {# campaigns}} )",
  "campaigns.completedCampaigns.small": "Completed Campaigns {br} ( {num} )",
  "pages.sort-by": "Sort By: ",
  "campaigns.pending.empty": "No pending campaigns",
  "campaigns.ongoing.active-campaigns": "You have {num} active {num, plural, one {campaign} other {campaigns}}",
  "campaigns.ongoing.empty": "No ongoing campaigns",
  "campaigns.completed.empty": "No completed campaigns",
  "campaigns.invitationLink": "Invite KOLs to your campaigns now!",
  "campaigns.invitations.empty": "Please search for any KOLs to invite for your campaign",
  "campaigns.submissions.no-submissions": "No submissions yet",
  "campaigns.submissions.no-pending-submissions": "No pending submissions",
  "campaigns.submissions.no-accepted-submissions": "No accepted submissions",
  "campaigns.submissions.no-rejected-submissions": "No rejected submissions",
  "campaigns.invitations.title": "Search for KOLs",
  "campaigns.invitations.empty-1": "You do not have any campaign invitations.",
  "campaigns.invitations.empty-2": "To create invitations, please click Invite KOLs",
  "campaigns.invitations.campaign-status": "Campaign Status",
  "campaigns.invitations.creators-invited": "KOLs Invited",
  "campaigns.invitations.creators-responded": "KOLs Responded",
  "campaigns.invitations.no-creators-found": "There aren't any KOLs matching the criteria selected",
  "campaigns.invitations.countries": "Countries ( click to filter )",
  "campaigns.invitations.social-media": "Social Media ( click to filter )",
  "campaigns.invitations.inviting-creators": "Inviting {count, plural, =0 {no KOLs} one {# KOL} other {# KOLs}}",
  "campaigns.invitations.inviting-to-campaigns": "Invite to selected campaigns",
  "campaigns.invitations.inviting-to-campaigns-confirm": "Invite {count, plural, one {# KOL} other {# KOLs}}?",
  "campaigns.invitations.confirm-invitation": "Successfully invited and shortlisted!",
  "campaigns.invitations.invitation-sent": "Invitation sent",
  "campaigns.invitations.sent-on": "Sent on",
  "campaigns.invitations.accepted": "Accepted",
  "campaigns.invitations.rejected": "Rejected",
  "campaigns.invitations.canceled": "Canceled",
  "campaigns.invitations.cancel-invitation": "Cancel invitation to this KOL?",
  "campaigns.invitations.invitation-canceled": "Invitation cancelled",
  "campaigns.invitations.remaining-attempts": "Remaining attempts: ",
  "campaigns/submissions/title": "Submissions for",
  "campaigns.submissions.total": "{pendingCount} Pending / {totalCount, plural, =0 {0 Submissions} one {# Total Submission} other {# Total Submissions}}",
  "campaigns.drafts.total": "{pendingCount} Pending / {totalCount, plural, =0 {0 Drafts} one {# Total Draft} other {# Total Drafts}}",
  "campaigns.draftDate": "Set a Draft Date",
  "campaigns.deliveryDate": "Set a Live Date",

  /* Campaigns - GSL */
  "campaigns.edit": "Edit Campaign Details",

  /* 08. Influencers */
  "influencers.address.title": "Address (brands will send products to this address)",
  "influencers.rate.context": "* Payment to your account will be made based in Malaysian Ringgit. Converted prices are provided as a convenience and are only an estimate based on current exchange rates. Local prices will change as the exchange rate fluctuates.",
  "influencers.rate.date": "* Rate as of: {dta} - 12pm",
  "influencers.navItem-0": "Profile",
  "influencers.navItem-1": "My Wallet",
  "influencers.navItem-2": "IG Insights",
  "influencers.proposal.brands": "Brands you have worked before?*",
  "influencers.proposal.context": "Please fill up everything in the tabs below (click on the tab to see the table)",
  "influencers.proposal.prices": "Your prices will help us to propose/suggest you to clients when they search for influencers, we will never share it in public.",
  "influencers.proposal.fb.item-1": "Price per Facebook live (20 mins)*",
  "influencers.proposal.inst.item-1": "Price per Instagram post (indoor)*",
  "influencers.proposal.inst.item-2": "Price per Instagram post (outdoor)*",
  "influencers.proposal.inst.item-3": "Price per Instagram post (video)*",
  "influencers.proposal.inst.item-4": "Price per Instagram Story*",
  "influencers.proposal.inst.item-5": "Price per Instagram TV",
  "influencers.proposal.general.item-1": "Price per day (acting - main)*",
  "influencers.proposal.general.item-2": "Price per day (acting - extra)*",
  "influencers.proposal.general.item-3": "Price per TikTok video",
  "influencers.proposal.general.item-4": "Price per event attendance*",
  "influencers.wallet.earnings": "Total Earnings",
  "influencers.wallet.pending": "Pending",
  "influencers.wallet.dispute": "Under Dispute",
  "influencers.wallet.withdrawable": "Withdrawable",
  "influencers.table.campaign": "Campaign Title & Payments",
  "influencers.table.item-1": "Campaign Transactions",
  "influencers.table.item-2": "Withdrawals",
  "influencers.table.item-3": "GSL Campaigns",
  "influencers.table.withdrew": "Withdrew?",
  "influencers.table.noWidthrawals": "No withdrawal yet",
  "influencers.table.reward": "Share Reward",
  "influencers.table.date": "Share Date",
  "influencers.account.charges": "Payout charges",
  "influencers.account.minWidth": "*Minimum Withdrawal",
  "influencers.payout.amount": "Payout Amounts",
  "influencers.insights.title": "Instagram Insights",
  "influencers.insights.context": "Upload your Instagram personal insights here",
  "influencers.insights.screenshots.title": "Uploaded screenshots (recent 3):",
  "influencers.insignts.neg-context": "No insights found",
  "influencers.submissions.title": "Submit your link here!",
  "influencers.submissions.context-1": "This is where you submit your { b } {name} url. To upload a draft, please upload them { link };",
  "influencers.submissions.context-2": "Make sure the link is live;",
  "influencers.submissions.context-3": "The caption will be similar to what you have posted for the {name} post.",
  "influencers.submissions.link": "Link my {cta} Now",
  "influencers.submissions.status.title": "Your submission status",
  "influencers.submissions.total": "You have made {num} submissions in total",
  "influencers.submissions.accepted": "Accepted",
  "influencers.submissions.rejected": "Rejected. Please resubmit",
  "influencers.submissions.pending": "Currently under review",
  "influencers.submissions.submit_link": "Submit Link",
  "influencers.submissions.view_status": "View Status",
  "influencers.submissions.pickDate": "Pick a date on a calendar",
  "influencers.submissions.submDate": "Expected submission date",


  /* 09. Case Study */
  "caseStudy.icon.likes": "Total likes",
  "caseStudy.icon.comments": "Total comments",
  "caseStudy.icon.totalEng": "Total Egagement Rate",
  "caseStudy.icon.influencers": "KOLs",

  /* 10. Messages */

  "chat.campaigns-brief": "Campaign's Brief",
  "chat.influencer-proposal": "KOL's Proposal",
  "chat.files-upload": "Files Upload / Download",
  "chat.view-drafts": "View Drafts",
  "chat.live-date": "Live Date",
  "chat.notes": "Notes",
  "chat.influencers": "KOLs",
  "chat.actions": "Actions",

  /* 11. Modal */

  "modal.accept-deliverables.title": "You are about to accept the deliverables.",
  "modal.accept-deliverables.sub-title": "Please make sure all the below points are done:",
  "modal.accept-deliverables.sub-title-1": "Discuss key messages of the product",
  "modal.accept-deliverables.sub-title-2": "Discuss the mood and tone of the post",
  "modal.accept-deliverables.sub-title-3": "If there are any specific phrases / words you would like our KOL to mention / avoid. Please let them know as well",
  "modal.set-deadlines.title": "You are about to set Draft and Live dates.",
  "modal.set-deadlines.sub-title": "Set deadlines for drafts and live dates.",

  /* 12. Deliverables */

  "deliverable.blogPost": "{num, plural, one {# Blog Post} other {# Blog Posts}}",
  "deliverable.facebookPost": "{num, plural, one {# Facebook Post} other {# Facebook Posts}}",
  "deliverable.facebookVideo": "{num, plural, one {# Facebook Video} other {# Facebook Videos}}",
  "deliverable.igPost": "{num, plural, one {# Instagram Post} other {# Instagram Posts}}",
  "deliverable.igStory": "{num, plural, one {# Instagram Story} other {# Instagram Stories}}",
  "deliverable.igTv": "{num, plural, one {# Instagram TV} other {# Instagram TV}}",
  "deliverable.linkInBio": "{num, plural, one {# Link in Bio} other {# Links in Bio}}",
  "deliverable.tikTokVideo": "{num, plural, one {# TikTok Video} other {# TikTok Videos}}",
  "deliverable.twitterPost": "{num, plural, one {# Twitter Post} other {# Twitter Posts}}",
  "deliverable.youtubeVideo": "{num, plural, one {# YouTube Video} other {# YouTube Videos}}",

  /* 13. Review */

  "review.title": "Review & Payment",
  "review.communication": "Clear Communication",
  "review.responsive": "Responsive & Timely",
  "review.content": "Content Quality",
  "review.value": "Value for Money",
  "review.comment": "Comments",
};

