import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authCurrentUser from './auth/reducer';
import chatApp from './chat/reducer';
import form from './form/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authCurrentUser, 
  chatApp,
  form,
});

export default reducers;