/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const defaultCurrency = "MYR";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
  { id: "id", name: "Indonesia - LTR", direction: "ltr" }
];

export const defaultProfileImage = "/assets/img/avatar.jpg";
export const defaultPicture = "/assets/img/default-picture.jpeg";

export const currencyOptions = [
  { id: "USD", name: "USD" },
  { id: "MYR", name: "MYR" },
  // { id: "enrtl", name: "English - RTL", direction: "rtl" },
  // { id: "id", name: "Indonesia - LTR", direction: "ltr" }
];

export const firebaseConfig = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
};

export const searchPath = "";
export const servicePath = "";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = true;
