import React from 'react';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Notification from './Notification';

const Notifications = ({
  notifications = [],
  onRequestHide,
  // enterTimeout = 400,
  // leaveTimeout = 400
}) => {

  const handleRequestHide = notification => {
    if (onRequestHide) {
      onRequestHide(notification);
    }
  };

  const className = classnames('notification-container', {
    'notification-container-empty': notifications.length === 0
  });

  return (
    <div className={className}>
      <TransitionGroup>
        {notifications.map((notification) => {
          const key = notification.id || new Date().getTime();

          return (
            <CSSTransition
              key={key}
              in={true}
              timeout={notification.timeOut}
              unmountOnExit
              classNames="notification"
            >
              <Notification
                type={notification.type}
                title={notification.title}
                message={notification.message}
                timeOut={notification.timeOut}
                onClick={notification.onClick}
                onRequestHide={() => handleRequestHide(notification)}
                customClassName={notification.customClassName}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div >
  );
};

export default Notifications;
