import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { useAuth } from "../hooks/auth";

const GraphqlClient = token => {
  const { getSessionToken, sessionToken } = useAuth();

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_BASE_WS_URL,
    options: {
      reconnect: true,
      connectionParams: async () => {
        // console.log('CP ST', sessionToken);
        // console.log('CP T', token);
        // console.log('CP T === CP ST', sessionToken === token);
        return {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
    }
  });

  const splitLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
        );
        if (message === "Could not verify JWT: JWTExpired" || message === "Malformed Authorization header") {
          getSessionToken();
        }
        return '';
      });
    if (networkError) {
      // console.log(`[Network error]: ${networkError}`);
      // props.history.push('/network-error') // redirect to network-error route
    }
  });

  const link = ApolloLink.from([
    errorLink,
    splitLink
  ]);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        influencers: {
          fields: {
            authorizations: {
              merge(_ignore, incoming) {
                return incoming;
              },
            },
          },
        }
      }
    })
  });

  return apolloClient;
};

export default GraphqlClient;