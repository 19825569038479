import React, { useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';

const Notification = ({
  type = 'info',
  title,
  message,
  timeOut = 5000,
  onClick,
  onRequestHide,
  customClassName
}) => {

  let timer = useRef();

  const requestHide = useCallback(onRequestHide, []);

  useEffect(() => {
    timer.current = setTimeout(requestHide, timeOut);

    return () => {
      clearTimeout(timer.current);
    }
  }, [requestHide, timeOut]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    requestHide();
  };

  const className = classnames(['notification filled', `notification-${type}`, customClassName]);
  title = title ? (<h3 className="title">{title}</h3>) : null;

  return (
    <div
      className={className}
      onClick={handleClick}
    >
      <div className="notification-message" role="alert">
        {title}
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

export default Notification;
