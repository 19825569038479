import React, { Suspense, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useAuth } from "./hooks/auth";
// import { setUserData } from "./redux/auth/actions";
// import { setRate } from "./redux/settings/actions";
import { IntlProvider } from "react-intl";
// import "./helpers/Firebase";
import AppLocale from "./lang";
import { getDirection } from "./helpers/Utils";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
// import { defaultCurrency } from "./constants/defaultValues";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import graphqlClient from './lib/graphqlClient';

// const UnauthenticatedLayout = React.lazy(() => import('./views/layouts/unauthenticated'));
const AuthenticatedLayout = React.lazy(() =>
  import('./views/brand')
);

const App = () => {
  const { sessionToken, role, getSessionToken } = useAuth();

  // const { locale } = useSelector(state => state.settings);
  // console.log('app locale', locale)

  let locale = "en";

  const currentAppLocale = AppLocale[locale];
  let currentLayout;

  useEffect(() => {
    // if (window.location.pathname !== '/callback') {
    //   login();
    //   setRate();
    //   localStorage.setItem("currentCurrency", localStorage.getItem('currentCurrency') || defaultCurrency);
    // };

    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    };
  }, []);

  if (sessionToken === undefined) {
    getSessionToken();
  } else if (sessionToken === null || !/^(brands|sv_admins)$/.test(role)) {
    if (role === 'influencers') {
      return window.location = 'https://influencers.sushivid.com'
    }
    window.location = process.env.REACT_APP_SV_AUTH_APP
  } else if (sessionToken) {
    currentLayout = <AuthenticatedLayout />;
  }

  // const login = async () => {
  //   const verified = await verify(setUserData, sessionToken);
  //   return verified;
  // };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <>
        <NotificationContainer />
        <ApolloProvider
          client={graphqlClient(sessionToken)}
        >
          <Suspense fallback={<div className="loading" />}>
            {currentLayout}
          </Suspense>
        </ApolloProvider>
      </>
    </IntlProvider>
  )
};

export default Sentry.withProfiler(App);
