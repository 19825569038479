import {
    CHANGE_LOCALE,
    CHANGE_CURRENCY,
    SET_NOTIFICATIONS,
    // SET_RATE,
} from '../actions';
// import { setUserData } from "../auth/actions";
// import { APIdata } from "../../helpers/api";

export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const changeCurrency = (currency) => {
    localStorage.setItem('currentCurrency', currency);
    return (
        {
            type: CHANGE_CURRENCY,
            payload: currency
        }
    )
}

// export const setRate = () => {
//     if (localStorage.getItem("rate") === null || new Date().getHours() > 12) {
//         // change the date from BE and rate and move it to after API
//         APIdata().get('/currencies').then(res => {
//             localStorage.setItem('rate', JSON.stringify({ date: new Date(), rate: res.data.currencies }));
//         })
//         return function (dispatch) {
//             // APIdata().get('/currencies').then(res => {
//             dispatch({
//                 type: SET_RATE,
//                 payload: localStorage.getItem("rate")
//             })
//             // })
//         }
//     }
//     else {
//         return (
//             {
//                 type: SET_RATE,
//                 payload: JSON.stringify({ date: new Date(), rate: JSON.parse(localStorage.getItem('rate')).rate })
//             }
//         )
//     }
// }

export const setNotifications = (unread_notifications) => {
    return (
        {
            type: SET_NOTIFICATIONS,
            payload: unread_notifications
        }
    )
}

