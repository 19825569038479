/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Pages:
- Errors
- Services
05.Buttons
06.Tables
07.Campaigns
*/

module.exports = {
  /* 01.General */
  "general.copyright": "SushiVid © 2019 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Influencer Login",
  "user.login-subtitle": "Continue with any of the following social media:",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail",
  "user.password": "Password",
  "user.forgot-password-question": "Forget password?",
  "user.fullname": "Full Name",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",

  /* 03.Menu */
  /* Sidebar */
  "menu.sidebar.home": "Home",
  "menu.sidebar.services": "Services",
  "menu.sidebar.campaigns": "Campaigns",
  "menu.sidebar.campaigns.myCampaigns": "My Campaigns",
  "menu.sidebar.campaigns.activeProjects": "Ongoing Campaigns",
  "menu.sidebar.campaigns.campaignsInvitations": "Campaigns Invitations",
  "menu.sidebar.campaigns.pendingProjects": "Pending Campaigns",
  "menu.sidebar.campaigns.new": "New Campaign",
  "menu.sidebar.campaigns.sponsorships": "Sponsorship",
  "menu.sidebar.submissions": "",
  "menu.sidebar.drafts": "",
  "menu.sidebar.modal.chat": "Chat",
  "menu.sidebar.modal.view": "View",
  "menu.app": "Home",
  "menu.dashboards": "Dashboards",
  "menu.gogo": "Gogo",
  "menu.start": "Welcome",
  "menu.second-menu": "Second Menu",
  "menu.second": "Second",
  "menu.ui": "UI",
  "menu.charts": "Charts",
  "menu.survey": "Survey",
  "menu.todo": "Todo",
  "menu.search": "Search",
  "menu.docs": "Docs",
  "menu.blank-page": "Blank Page",

  /* 04.Pages */

  /* Welcome */
  "pages.welcome.header": "Browse Templates past campaigns",
  "pages.welcome.cps": "Cost Per Share",
  "pages.welcome.cpp": "Cost Per Post",
  "pages.welcome.cpsale": "Cost Per Sale",

  /* Error Page */
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",

  /* Services */
  "pages.services.header": "Layanan kami",

  /* Services - SushiVid - Normal */
  "pages.services.sushiVidNormal.title": "Tawaran",
  "pages.services.sushiVidNormal.intros-1": "Tawaran dalam 1 menit",
  "pages.services.sushiVidNormal.intros-2": "Lihat brand-brand terbaru",
  "pages.services.sushiVidNormal.intros-3":
    "Jelajahi lebih dari 20 brand kapan saja",

  /* Services - SushiVid - VIP */
  "pages.services.sushiVidVIP.title": "Accept",
  "pages.services.sushiVidVIP.intros-1": "By invitation only!",
  "pages.services.sushiVidVIP.intros-2": "High chance of obtaining job",
  "pages.services.sushiVidVIP.intros-3": "Little to no proposal required",

  /* Services - GoShareLah */
  "pages.services.goShareLah.title": "Share",
  "pages.services.goShareLah.intros-1": "Share good deals, spread good news",
  "pages.services.goShareLah.intros-2": "No proposal required - Confirmed job",
  "pages.services.goShareLah.intros-3": "Go share lah!",

  /* Services - ConfirmPlusChop */
  "pages.services.confirmPlusChop.title": "Sell",
  "pages.services.confirmPlusChop.intros-1": "Request for product",
  "pages.services.confirmPlusChop.intros-2": "Create content your way",
  "pages.services.confirmPlusChop.intros-3": "Earn commission from each sale",

  /* Sponsorship */
  "pages.sponsorhip.header": "",

  /* Proposal */
  "pages.proposals.collabBoxinfluencers": "Welcome to your collaboration box! You have {num} KOLs in production",
  "pages.proposals.collabBoxinfluencer": "Welcome to your collaboration box! You have {num} KOL in production",

  /* Dashboard */
  "pages.dashboard.header": "Dasbor",
  "pages.dashboard.tableTitle": "Kampanye Saya (Hanya Menunggu & Sedang Berlangsung)",

  /* GSL */
  "pages.gsl.title": "",
  "pages.gsl.caption": "",
  "pages.gsl.hashtags": "",
  "pages.gsl.removeHashtags": "",
  "pages.gsl.dropzone": "",
  "pages.gsl.link": "",
  "pages.gsl.edit": "",
  "pages.gsl.country": "Target Country(s)",
  "pages.gsl.activateTierCPS": "Activate Tiering Cost Per Share?",
  "pages.gsl.shortenedLink": "",


  /* Brands */
  "pages.brands.navItem-1": "Profil",
  "pages.brands.navItem-2": "Akun",
  "pages.brands.navItem-3": "Transaksi",
  "pages.brands.navItem-2.subtitle-1": "",
  "pages.brands.navItem-2.subtitle-2": "",
  "pages.brands.profile.email": "Surel",
  "pages.brands.profile.website": "Situs web",
  "pages.brands.profile.countryCode": "Kode negara",
  "pages.brands.profile.phone": "Telepon",
  "pages.brands.profile.description": "Deskripsi",
  "pages.brands.password.currentPassword": "",
  "pages.brands.password.newPassword": "",
  "pages.brands.password.confirmNewPassword": "",
  "pages.brands.billing.title": "",
  "pages.brands.billing.billTo": "",
  "pages.brands.billing.addressLine1": "",
  "pages.brands.billing.addressLine2": "",
  "pages.brands.billing.city": "",
  "pages.brands.billing.state": "",
  "pages.brands.billing.postCode": "",
  "pages.brands.billing.country": "",
  "pages.brands.billing.attentionTo": "",
  "pages.brands.payment.title": "",
  "pages.brands.payment.amount": "",
  "pages.brands.payment.currency": "",
  "pages.brands.transactionTableTitle": "",
  "pages.brands.invoicesTableTitle": "",
  "pages.brands.walletBalance": "",
  "pages.brands.deposit": "",
  "pages.brands.notification": "",

  /* Invoice */
  "pages.invoice.goBackLink": "Go back to campaign",

  /* 05.Buttons */
  "button.update": "",
  "button.pay": "",
  "button.payNow": "",
  "button.viewMore": "Lihat lebih banyak lagi",
  "button.deposit": "",
  "button.chat": "Chat",
  "button.more": "More",
  "button.invite": "Invite",
  "button.viewBrief": "",
  "button.viewSubmissions": "",
  "button.goBack": "",
  "button.seeReview": "",
  "button.addReview": "",
  "button.payCreate": "",
  "button.download-pdf": "Muat Turun PDF",


  /* 06.Tables */
  "table.transaction.column.date": "",
  "table.transaction.column.payment": "",
  "table.transaction.column.transaction": "",
  "table.transaction.column.debit": "",
  "table.transaction.column.credit": "",
  "table.transaction.column.currency": "",
  "table.transaction.column.link": "",
  "table.invoices.column.date": "",
  "table.invoices.column.title": "",
  "table.invoices.column.amount": "",
  "table.invoices.column.link": "",
  "table.dashboard.colunm.title": "",
  "table.dashboard.colunm.proposals": "",
  "table.dashboard.colunm.status": "",

  /* 07.Campaigns */

  /* Campaigns - New Proposal */
  "campaigns.newProposal.title": "",
  "campaigns.newProposal.dropzone": "",
  "campaigns.newProposal.objective": "",
  "campaigns.newProposal.contentType": "",
  "campaigns.newProposal.selectCategory": "",
  "campaigns.newProposal.description": "",
  "campaigns.newProposal.consumersAge": "",
  "campaigns.newProposal.additionalInfo": "",
  "campaigns.newProposal.submission_deadline": "",
  "campaigns.proposals.unsorted": "",
  "campaigns.proposals.sorted": "",
  "campaigns.proposals.in_production": "",
  "campaigns.proposals.archived": "",
  "campaigns.pending.empty": "",
  "campaigns.ongoing.empty": "",
  "campaigns.completed.empty": "",
  "campaigns.invitationLink": "",
  "campaigns/invitations/empty": "",
  "campaigns/invitations/title": "",
  "campaigns/submissions/title": "",

  /* Influencers */
  "influencers.address.title": "",
  "influencers.rate.context": "",
  "influencers.rate.date": "",
};
