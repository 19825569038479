import {
    LOGOUT_BRAND,
    SET_COOKIE,
    SET_USER_DATA,
} from '../actions';

function getCookieValue(a) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

const INIT_STATE = {
    company_name: '',
    profile_picture: '',
    email: '',
    session: '',
    r_session: getCookieValue('r_session') || '',
    exp: getCookieValue('exp') || '',
};

export default (state = INIT_STATE, action) => {
    // console.log(action.type, action.payload)
    switch (action.type) {
        case LOGOUT_BRAND:
            return { ...state };
        case SET_COOKIE:
            return {
                ...state,
                session: action.payload.session,
                exp: action.payload.exp
            };
        case SET_USER_DATA:
            return {
                ...state,
                company_name: action.payload.company_name,
                profile_picture: action.payload.profile_picture,
                email: action.payload.email
            }
        default: return { ...state };
    }
}
