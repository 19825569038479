const userSessionToken = () => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/refresh_token`, {
      method: 'POST',
      credentials: 'include'
    }).then(response => {
      // console.log('response', response);
      if (response.status === 200) return response.json();
      return Promise.reject(response.statusText)
    })
  )
};

const userSignOut = () => {
  return (
    fetch(
      `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}/sign_out`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
  )
};

export { userSessionToken, userSignOut };