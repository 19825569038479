/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_RATE = "SET_RATE";

/* AUTH */
export const LOGOUT_BRAND = "LOGOUT_BRAND";
export const SET_COOKIE = "SET_COOKIE";
export const SET_USER_DATA = "SET_USER_DATA";

/* AUTH */
export const SAVE_FORM = "SAVE_FORM";

/* CHAT APP*/
export const CHAT_GET_CONTACTS = "CHAT_GET_CONTACTS";
export const CHAT_GET_CONTACTS_SUCCESS = "CHAT_GET_CONTACTS_SUCCESS";
export const CHAT_GET_CONTACTS_ERROR = "CHAT_GET_CONTACTS_ERROR";
export const CHAT_GET_CONVERSATIONS = "CHAT_GET_CONVERSATIONS";
export const CHAT_GET_CONVERSATIONS_SUCCESS = "CHAT_GET_CONVERSATIONS_SUCCESS";
export const CHAT_GET_CONVERSATIONS_ERROR = "CHAT_GET_CONVERSATIONS_ERROR";
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  "CHAT_ADD_MESSAGE_TO_CONVERSATION";
export const CHAT_CREATE_CONVERSATION = "CHAT_CREATE_CONVERSATION";
export const CHAT_SEARCH_CONTACT = "CHAT_SEARCH_CONTACT";
export const CHAT_CHANGE_CONVERSATION = "CHAT_CHANGE_CONVERSATION";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./chat/actions";
export * from "./form/actions";
