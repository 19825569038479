import React, { useState, useContext, createContext } from 'react';
import * as userAction from '../helpers/auth';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
};

const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [userSession, setUserSession] = useState({
    isLoggedIn: false
  });

  const {
    token: sessionToken,
    // expiresIn,
    isLoggedIn: isUserLoggedIn,
    role, uid
  } = userSession;

  const setSession = ({ jwtToken, exp, role, uid }) => {
    setUserSession({
      token: jwtToken,
      expiresIn: exp,
      role,
      uid,
      isLoggedIn: true
    });
  };

  const getSessionToken = () => {
    userAction.userSessionToken()
      // .then(data => {
      .then(({ jwtToken, exp, role }) => {
        // console.log('/refresh_token data', data);

        // const { jwtToken, exp, role } = data;
        setUserSession({
          token: jwtToken,
          expiresIn: exp,
          role,
          isLoggedIn: true
        })
      }).catch(() => {
        setUserSession({
          token: null,
          expiresIn: 0,
          isLoggedIn: false
        });
      });
  };

  const signOut = () => {
    return userAction.userSignOut()
      .then(res => {
        setUserSession({
          token: null,
          expiresIn: 0,
          isLoggedIn: false,
          role: null
        });
        return res;
      })
  };

  return {
    sessionToken,
    role, uid,
    isUserLoggedIn,
    setSession,
    getSessionToken,
    signOut
  };
};

export { AuthProvider, useAuth };