import {
	CHAT_GET_CONTACTS,
	CHAT_GET_CONTACTS_SUCCESS,
	CHAT_GET_CONTACTS_ERROR,
	CHAT_ADD_MESSAGE_TO_CONVERSATION,
	CHAT_CREATE_CONVERSATION,
} from '../actions';

const INIT_STATE = {
	allContacts: null,
	contacts: null,
	error: '',
	loadingContacts: false,
	selectedUser: null,
	selectedUserId: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case CHAT_GET_CONTACTS:
			return { ...state, loadingContacts: false };

		case CHAT_GET_CONTACTS_SUCCESS:
			return { ...state, loadingContacts: true, contacts: action.payload.contacts, allContacts:  action.payload.contacts};

		case CHAT_GET_CONTACTS_ERROR:
			return { ...state, loadingContacts: false, error: action.payload };

		case CHAT_ADD_MESSAGE_TO_CONVERSATION:
			return { ...state };

		case CHAT_CREATE_CONVERSATION:
			return { ...state };

		default: return { ...state };
	}
}