
import {defaultLocale, defaultCurrency, localeOptions, currencyOptions} from '../../constants/defaultValues'

import {
	CHANGE_LOCALE,
	CHANGE_CURRENCY,
	SET_NOTIFICATIONS
} from '../actions';

const INIT_STATE = {
	currency: (localStorage.getItem('currentCurrency') && currencyOptions.filter(x=>x.id===localStorage.getItem('currentCurrency')).length>0) ? localStorage.getItem('currentCurrency') : defaultCurrency,
	locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x=>x.id===localStorage.getItem('currentLanguage')).length>0) ? localStorage.getItem('currentLanguage') : defaultLocale,
	unread_notifications: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return { ...state, locale:action.payload};
		case CHANGE_CURRENCY:
			return { ...state, currency:action.payload};
		case SET_NOTIFICATIONS:
			return { ...state, unread_notifications: action.payload}
		default: return { ...state };
	}
}