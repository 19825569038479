import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { AuthProvider } from './hooks/auth';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: "https://347508946a184f668383ad7e3e098c4b@o571031.ingest.sentry.io/5718652",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
